:root {
    --primary-color: #32774e;
    --primary-color-08: rgba(50, 119, 78, 0.8);
    --secondary-color: #519862;
    --secondary-color-07: rgba(81, 152, 98, 0.7);
    --sidebar-background-color: #fcf9ee;
    --background-color: #fcf9ee;
    
    --gray-color: #cbcccc;
    --neutral-light: #f0f1f2;
    --neutral-medium: #808284;
    --neutral-dark: #323334;
    
    --red-color: #db2828;
    --pink-color: #f77777;
    --green-color: #32774e;
    --gray-color: #cbcccc;

    --positive : #9DD49A;
    --negative: #FFA27F;

    --box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    --box-shadow-header: 2px 2px 8px 5px rgba(0, 0, 0, 0.3);
}